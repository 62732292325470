module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#137CD8","showSpinner":false,"minimum":0.25},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","bn-in","hi-in","kn-in","mr-in","pa-in","ta-in","te-in","en-in","sw-tz","en-tz","id","en-id","th","en-th","tl-ph","sw-ke","en-ke","vn"],"defaultLanguage":"en","siteUrl":"https://yaradigital.stage.apac.yaradigitallabs.io","generateDefaultLanguagePage":true,"redirect":false,"i18nextOptions":{"fallbackLng":"en","interpolation":{"escapeValue":false},"lowerCaseLng":true,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
